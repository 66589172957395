import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import {
  fetchProducts,
  addProduct,
  updateProduct,
  deleteProduct,
  eolProduct,
  notEolProduct,
} from '../services/product.service.js'

const productsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = productsAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        productsAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(addProduct.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        productsAdapter.upsertOne(state, action.payload)
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(updateProduct.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        productsAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deleteProduct.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        productsAdapter.removeOne(state, action.payload.id)
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(eolProduct.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(eolProduct.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        productsAdapter.upsertOne(state, action.payload)
      })
      .addCase(eolProduct.rejected, (state, action) => {
        state.status = 'failed'
      })
      .addCase(notEolProduct.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(notEolProduct.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        productsAdapter.upsertOne(state, action.payload)
      })
      .addCase(notEolProduct.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
  },
})

export default productsSlice.reducer

export const {
  selectAll: selectAllProducts,
  selectById: selectProductById,
  selectIds: selectProductIds,
} = productsAdapter.getSelectors((state) => state.products)
