import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

const notificationsAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id - b.id,
})

const initialState = notificationsAdapter.getInitialState({})

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    addNotification(state, action) {
      const type = action.payload.type
      const message = action.payload.message
      const title = action.payload.title
      const id = Date.now()

      notificationsAdapter.upsertOne(state, { id, type, message, title })
    },
    clearNotifications(state) {
      notificationsAdapter.removeAll(state)
    },
    deleteNotification(state, action) {
      notificationsAdapter.removeOne(state, action.payload)
    },
  },
})

export const { addNotification, clearNotifications, deleteNotification } = notificationSlice.actions
export default notificationSlice.reducer

export const {
  selectAll: selectAllNotifications,
  selectById: selectNotificationById,
  selectIds: selectNotificationIds,
} = notificationsAdapter.getSelectors((state) => state.notifications)
