import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAPIUrlForEndpoint, getRequest } from './service.helper'

export const fetchRoles = createAsyncThunk('roles/fetchRoles', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/role'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
