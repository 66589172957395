import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import {
  fetchLicenses,
  fetchAllLicenses,
  addLicense,
  updateLicense,
  deleteLicense,
  approveLicense,
  rejectLicense,
  reapproveLicense,
  revokeLicense,
} from '../services/license.service.js'

const licensesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = licensesAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const licensesSlice = createSlice({
  name: 'licenses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenses.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchLicenses.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        licensesAdapter.removeAll(state)
        licensesAdapter.setMany(state, action.payload)
      })
      .addCase(fetchLicenses.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(fetchAllLicenses.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchAllLicenses.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        licensesAdapter.removeAll(state)
        licensesAdapter.setMany(state, action.payload)
      })
      .addCase(fetchAllLicenses.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(addLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(addLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(addLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(updateLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(updateLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deleteLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deleteLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.removeOne(state, action.payload.id)
      })
      .addCase(deleteLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(approveLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(approveLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(approveLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(rejectLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(rejectLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(rejectLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(reapproveLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(reapproveLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(reapproveLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(revokeLicense.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(revokeLicense.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        licensesAdapter.upsertOne(state, action.payload)
      })
      .addCase(revokeLicense.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
  },
})

export default licensesSlice.reducer

export const {
  selectAll: selectAllLicenses,
  selectById: selectLicenseById,
  selectIds: selectLicenseIds,
} = licensesAdapter.getSelectors((state) => state.licenses)
