import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { fetchLicenseTypes } from '../services/licenseType.service.js'

const licenseTypeAdapter = createEntityAdapter({
  sortComparer: (a, b) => a.id - b.id,
})

const initialState = licenseTypeAdapter.getInitialState({
  status: 'idle',
})

const licenseTypeSlice = createSlice({
  name: 'licenseTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLicenseTypes.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchLicenseTypes.fulfilled, (state, action) => {
        state.status = 'succeeded'
        licenseTypeAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchLicenseTypes.rejected, (state, action) => {
        state.status = 'failed'
      })
  },
})

export default licenseTypeSlice.reducer

export const {
  selectAll: selectAllLicenseTypes,
  selectById: selectLicenseTypeById,
  selectIds: selectLicenseTypeIds,
} = licenseTypeAdapter.getSelectors((state) => state.licenseTypes)
