import { createSlice } from '@reduxjs/toolkit'

import { loginUser, logoutUser } from 'src/services/auth.service'

const helperSlice = createSlice({
  name: 'helper',
  initialState: {
    sidebarShow: true,
    sidebarUnfoldable: false,
    activeTenant: null,
    user: null,
    userLoggedIn: false,
  },
  reducers: {
    setSidebarShow: (state, action) => {
      state.sidebarShow = action.payload
    },
    setSidebarUnfoldable: (state, action) => {
      state.sidebarUnfoldable = action.payload
    },
    setActiveTenant: (state, action) => {
      state.activeTenant = action.payload
    },
    removeActiveTenant: (state, action) => {
      state.activeTenant = null
    },
    resetPersistedState: (state, action) => {
      state.sidebarShow = true
      state.sidebarUnfoldable = false
      state.activeTenant = null
      state.user = null
      state.userLoggedIn = false
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.fulfilled, (state, action) => {
        state.user = action.payload.user
        state.userLoggedIn = true
      })
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.user = null
        state.userLoggedIn = false
      })
  },
})

export const {
  setSidebarShow,
  setSidebarUnfoldable,
  setActiveTenant,
  removeActiveTenant,
  resetPersistedState,
} = helperSlice.actions

export default helperSlice.reducer

export const getSidebarShow = (state) => state.helper.sidebarShow
export const getSidebarUnfoldable = (state) => state.helper.sidebarUnfoldable
export const getActiveTenant = (state) => state.helper.activeTenant
export const getActiveTenantId = (state) => {
  if (state.helper.activeTenant) {
    return state.helper.activeTenant.id
  }
  return null
}
export const getLoggedInUser = (state) => state.helper.user
export const getUserLoggedIn = (state) => state.helper.userLoggedIn
export const getUserGlobalAdmin = (state) => state.helper.user.is_global_admin
export const getUserTenantAdmin = (state) => {
  if (getUserGlobalAdmin(state)) {
    return true
  } else {
    const tenant = getActiveTenant(state)
    if (tenant) {
      return state.helper.user.tenants[tenant.id].is_tenant_admin
    }
  }
  return false
}
