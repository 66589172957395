import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import {
  fetchUsers,
  addUser,
  updateUser,
  deleteUser,
  enableUser,
  deactivateUser,
  resetUserPassword,
} from '../services/user.service.js'

const usersAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = usersAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        usersAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(addUser.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.upsertOne(state, action.payload)
      })
      .addCase(addUser.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(updateUser.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deleteUser.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.removeOne(state, action.payload.id)
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(enableUser.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(enableUser.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.upsertOne(state, action.payload)
      })
      .addCase(enableUser.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deactivateUser.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.upsertOne(state, action.payload)
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(resetUserPassword.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(resetUserPassword.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        usersAdapter.upsertOne(state, action.payload)
      })
      .addCase(resetUserPassword.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
  },
})

export default usersSlice.reducer

export const {
  selectAll: selectAllUsers,
  selectById: selectUserById,
  selectIds: selectUserIds,
} = usersAdapter.getSelectors((state) => state.users)
