import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAPIUrlForEndpoint,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './service.helper'

export const fetchProducts = createAsyncThunk('products/fetchProducts', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/product'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const addProduct = createAsyncThunk('products/addProduct', async (product) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/product'), product)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const updateProduct = createAsyncThunk('products/updateProduct', async (product) => {
  const productId = product.id
  const response = await putRequest(getAPIUrlForEndpoint(`/api/product/${productId}`), product)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deleteProduct = createAsyncThunk('products/deleteProduct', async (productId) => {
  const response = await deleteRequest(getAPIUrlForEndpoint(`/api/product/${productId}`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const eolProduct = createAsyncThunk('products/eolProduct', async (productId) => {
  const response = await putRequest(getAPIUrlForEndpoint(`/api/product/${productId}/end_of_life`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const notEolProduct = createAsyncThunk('products/notEolProduct', async (productId) => {
  const response = await putRequest(getAPIUrlForEndpoint(`/api/product/${productId}/in_life`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
