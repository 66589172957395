import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAPIUrlForEndpoint,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './service.helper'

export const fetchUsers = createAsyncThunk('users/fetchUsers', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/user'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const addUser = createAsyncThunk('users/addUser', async (user) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/user'), user)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const updateUser = createAsyncThunk('users/updateUser', async (user) => {
  const userId = user.id
  const userBody = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    roles: user.roles,
  }
  const response = await putRequest(getAPIUrlForEndpoint(`/api/user/${userId}`), userBody)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deleteUser = createAsyncThunk('users/deleteUser', async (user) => {
  const response = await deleteRequest(getAPIUrlForEndpoint(`/api/user/${user.id}`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const enableUser = createAsyncThunk('users/enableUser', async (user) => {
  const response = await postRequest(getAPIUrlForEndpoint(`/api/user/${user.id}/enable`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deactivateUser = createAsyncThunk('users/deactivateUser', async (user) => {
  const response = await postRequest(getAPIUrlForEndpoint(`/api/user/${user.id}/disable`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const resetUserPassword = createAsyncThunk('users/resetUserPassword', async (user) => {
  const body = {
    password: user.password,
  }
  const response = await postRequest(getAPIUrlForEndpoint(`/api/user/${user.id}/reset`), body)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
