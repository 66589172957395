import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAPIUrlForEndpoint, getRequest, postRequest, deleteRequest } from './service.helper'

export const fetchSigningKeys = createAsyncThunk('signingKeys/fetchSigningKeys', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/signing_key'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const addSigningKey = createAsyncThunk('signingKeys/addSigningKey', async (signingKey) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/signing_key'), signingKey)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deleteSigningKey = createAsyncThunk(
  'signingKeys/deleteSigningKey',
  async (signingKeyId) => {
    const response = await deleteRequest(getAPIUrlForEndpoint(`/api/signing_key/${signingKeyId}`))
    if (response.error) {
      throw new Error(response.error)
    } else {
      return response.data
    }
  },
)
