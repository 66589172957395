import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAPIUrlForEndpoint, postRequest } from './service.helper'

export const loginUser = createAsyncThunk('helper/loginUser', async (loginInformation) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/auth/login'), loginInformation)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const logoutUser = createAsyncThunk('helper/logoutUser', async () => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/auth/logout'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
