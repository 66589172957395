export const getAPIUrlForEndpoint = (endpoint) => {
  switch (process.env.NODE_ENV) {
    case 'production':
      return endpoint
    case 'development':
    default:
      return `http://localhost:5000${endpoint}`
  }
}

async function handleError(response) {
  switch (response.status) {
    case 404:
      return 'Not found'
    case 401:
      return 'Unauthorized'
    case 403:
      return 'Permission denied'
    default:
      const data = await response.json()
      if (data.error) {
        console.error(data.error)
        return data.error
      } else {
        if (response.status === 500) {
          return 'Internal Server Error'
        } else if (response.status === 400) {
          return 'Bad Request'
        } else {
          return response.statusText
        }
      }
  }
}

export async function getRequest(url) {
  const response = await fetch(url, {
    credentials: 'include',
    method: 'GET',
  })

  if (!response.ok) {
    return { data: null, error: await handleError(response) }
  } else {
    return { data: await response.json(), error: null }
  }
}

export async function postRequest(url, body) {
  const response = await fetch(url, {
    credentials: 'include',
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    return { data: null, error: await handleError(response) }
  } else {
    return { data: await response.json(), error: null }
  }
}

export async function putRequest(url, body) {
  const response = await fetch(url, {
    credentials: 'include',
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body),
  })

  if (!response.ok) {
    return { data: null, error: await handleError(response) }
  } else {
    return { data: await response.json(), error: null }
  }
}

export async function deleteRequest(url) {
  const response = await fetch(url, {
    credentials: 'include',
    method: 'DELETE',
  })

  if (!response.ok) {
    return { data: null, error: await handleError(response) }
  } else {
    return { data: await response.json(), error: null }
  }
}
