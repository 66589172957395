import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { fetchRoles } from '../services/role.service.js'

const rolesAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = rolesAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        rolesAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
  },
})

export default rolesSlice.reducer

export const {
  selectAll: selectAllRoles,
  selectById: selectRoleById,
  selectIds: selectRoleIds,
} = rolesAdapter.getSelectors((state) => state.roles)
