import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAPIUrlForEndpoint,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './service.helper'

export const fetchLicenses = createAsyncThunk('licenses/fetchLicenses', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/license'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const fetchAllLicenses = createAsyncThunk('licenses/fetchAllLicenses', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/license/all'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const addLicense = createAsyncThunk('licenses/addLicense', async (license) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/license'), license)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const updateLicense = createAsyncThunk('licenses/updateLicense', async (license) => {
  const licenseId = license.id
  const response = await putRequest(getAPIUrlForEndpoint(`/api/license/${licenseId}`), license)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deleteLicense = createAsyncThunk('licenses/deleteLicense', async (license) => {
  const response = await deleteRequest(getAPIUrlForEndpoint(`/api/license/${license.id}`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const approveLicense = createAsyncThunk('licenses/approveLicense', async (license) => {
  const postJson = {
    comment: license.comment,
    key_id: license.key_id,
  }
  const response = await postRequest(
    getAPIUrlForEndpoint(`/api/license/${license.id}/approve`),
    postJson,
  )
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const rejectLicense = createAsyncThunk('licenses/rejectLicense', async (license) => {
  const postJson = {
    comment: license.comment,
  }
  const response = await postRequest(
    getAPIUrlForEndpoint(`/api/license/${license.id}/reject`),
    postJson,
  )
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const reapproveLicense = createAsyncThunk('licenses/reapproveLicense', async (license) => {
  const postJson = {
    comment: license.comment,
    key_id: license.key_id,
  }
  const response = await postRequest(
    getAPIUrlForEndpoint(`/api/license/${license.id}/reapprove`),
    postJson,
  )
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const revokeLicense = createAsyncThunk('licenses/revokeLicense', async (license) => {
  const postJson = {
    comment: license.comment,
  }
  const response = await postRequest(
    getAPIUrlForEndpoint(`/api/license/${license.id}/revoke`),
    postJson,
  )
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
