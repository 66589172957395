import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import { fetchTenants, addTenant, updateTenant, deleteTenant } from '../services/tenant.service.js'

const tenantsAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = tenantsAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const tenantsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenants.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        tenantsAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(addTenant.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(addTenant.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        tenantsAdapter.upsertOne(state, action.payload)
      })
      .addCase(addTenant.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(updateTenant.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(updateTenant.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        tenantsAdapter.upsertOne(state, action.payload)
      })
      .addCase(updateTenant.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deleteTenant.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deleteTenant.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        tenantsAdapter.removeOne(state, action.payload.id)
      })
      .addCase(deleteTenant.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
  },
})

export default tenantsSlice.reducer

export const {
  selectAll: selectAllTenants,
  selectById: selectTenantById,
  selectIds: selectTenantIds,
} = tenantsAdapter.getSelectors((state) => state.tenants)

export const getResellerTenants = (state) => {
  return selectAllTenants(state).filter((tenant) => tenant.reseller)
}
