import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

import { getUserLoggedIn } from 'src/slices/helperSlice'

const AuthenticatedRoute = () => {
  const auth = useSelector((state) => getUserLoggedIn(state))
  return auth ? <Outlet /> : <Navigate to="/login" />
}

export default AuthenticatedRoute
