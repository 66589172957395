import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

import {
  fetchSigningKeys,
  addSigningKey,
  deleteSigningKey,
} from '../services/signingKey.service.js'

const signingKeysAdapter = createEntityAdapter({
  sortComparer: (a, b) => b.id > a.id,
})

const initialState = signingKeysAdapter.getInitialState({
  loadStatus: 'idle',
  actionStatus: 'idle',
})

const signingKeysSlice = createSlice({
  name: 'signingKeys',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSigningKeys.pending, (state, action) => {
        state.loadStatus = 'loading'
      })
      .addCase(fetchSigningKeys.fulfilled, (state, action) => {
        state.loadStatus = 'succeeded'
        signingKeysAdapter.upsertMany(state, action.payload)
      })
      .addCase(fetchSigningKeys.rejected, (state, action) => {
        state.loadStatus = 'failed'
      })
      .addCase(addSigningKey.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(addSigningKey.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        signingKeysAdapter.upsertOne(state, action.payload)
      })
      .addCase(addSigningKey.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
      .addCase(deleteSigningKey.pending, (state, action) => {
        state.actionStatus = 'loading'
      })
      .addCase(deleteSigningKey.fulfilled, (state, action) => {
        state.actionStatus = 'succeeded'
        signingKeysAdapter.removeOne(state, action.payload.id)
      })
      .addCase(deleteSigningKey.rejected, (state, action) => {
        state.actionStatus = 'failed'
      })
  },
})

export default signingKeysSlice.reducer

export const {
  selectAll: selectAllSigningKeys,
  selectById: selectSigningKeyById,
  selectIds: selectSigningKeyIds,
} = signingKeysAdapter.getSelectors((state) => state.signingKeys)
