import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getAPIUrlForEndpoint,
  getRequest,
  postRequest,
  putRequest,
  deleteRequest,
} from './service.helper'

export const fetchTenants = createAsyncThunk('tenants/fetchTenants', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/tenant'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const addTenant = createAsyncThunk('tenants/addTenant', async (tenant) => {
  const response = await postRequest(getAPIUrlForEndpoint('/api/tenant'), tenant)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const updateTenant = createAsyncThunk('tenants/updateTenant', async (updateObject) => {
  const tenantId = updateObject.tenantId
  const data = { owner_id: updateObject.ownerId }
  const response = await putRequest(getAPIUrlForEndpoint(`/api/tenant/${tenantId}`), data)
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deleteTenant = createAsyncThunk('tenants/deleteTenant', async (tenantId) => {
  const response = await deleteRequest(getAPIUrlForEndpoint(`/api/tenant/${tenantId}`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const activateTenant = createAsyncThunk('tenants/activateTenant', async (tenantId) => {
  const response = await postRequest(getAPIUrlForEndpoint(`/api/tenant/${tenantId}/activate`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})

export const deactivateTenant = createAsyncThunk('tenants/deactivateTenant', async () => {
  const response = await postRequest(getAPIUrlForEndpoint(`/api/tenant/deactivate`))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
