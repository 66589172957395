import { configureStore, combineReducers } from '@reduxjs/toolkit'

import { persistStore, persistReducer } from 'redux-persist'
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'

import helperReducer from './slices/helperSlice'
import notificationReducer from './slices/notificationSlice'
import productReducer from './slices/productSlice'
import licenseTypeReducer from './slices/licenseTypeSlice'
import tenantReducer from './slices/tenantSlice'
import licenseReducer from './slices/licenseSlice'
import signingKeyReducer from './slices/signingKeySlice'
import userReducer from './slices/userSlice'
import roleReducer from './slices/roleSlice'

const persistConfig = {
  key: 'root',
  storage: storageSession,
  whitelist: ['helper'],
}

const rootReducer = combineReducers({
  helper: helperReducer,
  notifications: notificationReducer,
  products: productReducer,
  licenseTypes: licenseTypeReducer,
  tenants: tenantReducer,
  licenses: licenseReducer,
  signingKeys: signingKeyReducer,
  users: userReducer,
  roles: roleReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
})

export const persistor = persistStore(store)
