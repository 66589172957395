import { createAsyncThunk } from '@reduxjs/toolkit'
import { getAPIUrlForEndpoint, getRequest } from './service.helper'

export const fetchLicenseTypes = createAsyncThunk('licenseTypes/fetchLicenseTypes', async () => {
  const response = await getRequest(getAPIUrlForEndpoint('/api/license_type'))
  if (response.error) {
    throw new Error(response.error)
  } else {
    return response.data
  }
})
